import * as React from "react"
import Helmet from "react-helmet"

import Layout from '../components/layout.js'
import HeroShort from '../components/hero-short.js'
import Contact from '../components/contact.js'

const AboutPage = () => {
  return (
    <Layout>

      <Helmet>
        <title>Home Page</title>
      </Helmet>

      <HeroShort title="Projects 📤" />

      <div className="section main">
        <div className="wrap container">

          <div className="row blog-list">

            <div className="col-xs-12 col-md-4 blog-item">
              <p className="h3">Fiddle & Thorn</p>
              <p>Low impact niche-website rebuild with super performant front-end.</p>
              <p><a href="/">Find out more</a></p>
            </div>

            <div className="col-xs-12 col-md-4 blog-item">
              <p className="h3">RollCall</p>
              <p>Slack based desk booking system for remote-first businesses.</p>
              <p><a href="/">Find out more</a></p>
            </div>

            <div className="col-xs-12 col-md-4 blog-item">
              <p className="h3">Downtime</p>
              <p>Get website downtime alerts directly in Slack as it happens.</p>
              <p><a href="/">Find out more</a></p>
            </div>

            <div className="col-xs-12 col-md-4 blog-item">
              <p className="h3">Team Dashboard</p>
              <p>Complete marketing agency management tool.</p>
              <p><a href="/">Find out more</a></p>
            </div>

            <div className="col-xs-12 col-md-4 blog-item">
              <p className="h3">Yakky</p>
              <p>Shopify based auction site for second-hand commercial goods.</p>
              <p><a href="/">Find out more</a></p>
            </div>

            <div className="col-xs-12 col-md-4 blog-item">
              <p className="h3">Seed</p>
              <p>WordPress + Gatsby website for the marketing agency I work for.</p>
              <p><a href="/">Find out more</a></p>
            </div>

          </div>
        </div>
      </div>

      <Contact colour="green" />

    </Layout>
  )
}

export default AboutPage
