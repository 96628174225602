import React from 'react';

const HeroShort = ({title}) => {
  return (
    <div className="section header">
      <div className="wrap container">
        <div className="row">
          <div className="col-xs-12">
            <h1>{title}</h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroShort
